import React, { useState } from 'react'
import { Button, Frame, Pill, Typography } from 'components'
import { TextField, Box } from 'components'
import { updateUser } from 'utils'
import { CUSTOM_STATIC_LANDER, AVAILABLE_FROM_CALENDAR } from 'constants'
import IntroDialog from '../FreelancerMonth/IntroDialog'

const FreelancerEquipment = ({ history, user, onboarding, onNext = () => {} }) => {
  const [equipment, setEquipment] = useState(user.equipment || [])
  const [newItem, setNewItem] = useState('')
  const params = new URLSearchParams(window.location.search.split('?')[1])
  const [dialogOpen, setDialogOpen] = useState(false)

  const addItem = () => {
    setNewItem('')
    setEquipment((w) => [...w, newItem])
  }

  const removeItem = (item) => () => {
    const shouldRemove = window.confirm(
      'Are you sure you want to remove this item from your equipment?',
    )
    if (!shouldRemove) return

    setEquipment((e) => e.filter((_item) => _item !== item))
  }

  const onSave = () => {
    updateUser(user, { equipment })
    if (onboarding && !AVAILABLE_FROM_CALENDAR) {
      history.push(`/month?source=onboarding`)
    } else if (onboarding && CUSTOM_STATIC_LANDER) {
      // setDialogOpen(true)
      onNext()
    } else {
      history.push('profile')
    }
  }

  const onCancel = () => {
    if (onboarding && !CUSTOM_STATIC_LANDER) {
      history.push(`/month?source=onboarding`)
    } else if (onboarding && CUSTOM_STATIC_LANDER) {
      // setDialogOpen(true)
      onNext()
    }
    else {
      history.push('profile')
    }
  }

  return (
    <Frame py={2.5} maxWidth="lg" user={user}>
      <Box maxWidth={450} mx="auto">
        <Typography align="center" variant="h3">
          {onboarding
            ? 'Do you have your own gear?'
            : 'Equipment Tags'}
        </Typography>

        <Box mt={2} />
        {!CUSTOM_STATIC_LANDER ? (
          <Typography>
            Production Crew are often hired for their gear. Cameras, lighting,
            data management, drones, vehicles. If you use it for your job, list
            it! Be specific where it matters.
          </Typography>
        ) : (
          <Typography>
            Sometimes companies hire you because you have the right gear or software.
            Especially for smaller companies or Freelance (IC) jobs.
            If you have gear or software that's an important for your job, list it here.
          </Typography>
        )}


        <Box mt={4} mb={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Current Equipment</Typography>
            <Typography style={{ fontSize: 13 }}>
              Click an item below to remove it
            </Typography>
          </Box>

          <Box my={2} />

          {equipment.map((item, i) => (
            <Pill
              bgcolor="white"
              key={item + i}
              style={{ marginRight: 8, marginBottom: 8 }}
              onClick={removeItem(item)}
            >
              {item}
            </Pill>
          ))}
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            addItem()
          }}
        >
          <TextField
            name="equipment"
            label="Add new Equipment"
            placeholder="Enter one at a time, i.e., Canon XA11 4K"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />
        </form>

        <Button
          disabled={!newItem}
          color="primary"
          variant="contained"
          onClick={addItem}
        >
          Add to List
        </Button>

        <Box display="flex" mt={6}>
          <Button
            color="primary"
            id="profile-edit-submit"
            variant="contained"
            style={{ flex: 1, marginRight: 8 }}
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            id="profile-edit-submit"
            variant="outlined"
            style={{ flex: 1 }}
            onClick={onCancel}
          >
            {onboarding
              ? 'Remind me later'
              : 'Cancel'}
          </Button>
        </Box>
      </Box>
      <IntroDialog open={dialogOpen} onClose={() => setDialogOpen(false)} onNext={onNext} />
    </Frame>
  )
}

export default FreelancerEquipment
