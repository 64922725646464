import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ExperienceSlider,
  Typography,
  Frame,
  Box,
  Heading,
  Checklist,
  TextField,
  TagsForm,
} from 'components'
import omit from 'lodash/omit'
import firebase from 'utils/firebase'
import Fuse from 'fuse.js'
import { debounce, uniqBy } from 'lodash'
import AddRoleDialog from 'components/freelancer/AddRoleDialog'
import { getRolesFromMarkets, useContent, useHistory } from 'utils'
import { NUM_ONBOARDING_STEPS, DISABLE_INDUSTRY_STEP, NUM_ROLE_STEPS, NUM_PRE_ROLE_ONBOARDING_STEPS } from 'constants'
import { FreelancerRoleList } from './FreelancerRoleList'
import { getonboarding } from '../FreelancerMonth'
import { ProgressBarFooter } from '../FreelancerCreate/FreelancerProgressBarFooter'

export const FreelancerAddRole = ({ user, markets, createRole, tags, onboarding, stepIndex: onboardingStepIndex, totalSteps, onNext = () => {}, onSubmit = () => {} }) => {
  const history = useHistory()
  // const onboarding = getonboarding()
  let firstStep = onboarding ? onboardingStepIndex  + 1 : 1
  const numSteps = onboarding ? totalSteps : NUM_ROLE_STEPS

  const [state, setState] = useState({ stepIndex: firstStep })

  useEffect(() => {
    setState({ stepIndex: onboarding ? onboardingStepIndex + 1 : 1 })
  }, [onboarding])

  const updateState = (update) =>
    setState((s) => {
      if (onboarding && s.stepIndex === 2 && update.stepIndex === 3) {
        firebase.analytics().logEvent('user_freelancer_industry_submit_click')
      }
      if (onboarding && s.stepIndex === 3 && update.stepIndex === 4) {
        firebase.analytics().logEvent('user_freelancer_role_submit_click')
      }
      if (onboarding && s.stepIndex === 4 && update.stepIndex === 5) {
        firebase.analytics().logEvent('user_freelancer_experience_submit_click')
      }

      return { ...s, ...update }
    })

  const {
    stepIndex,
    industryId,
    industry,
    label,
    roleId,
    experience,
    skills = [],
  } = state
  let _stepIndex = stepIndex - firstStep

  let steps = [
    { key: 'role', validator: () => !!roleId, child: <FreelancerRoleForm hasRoles={Object.keys(user.roles).length > 0} markets={markets} onChange={updateState} state={state} /> },
    DISABLE_INDUSTRY_STEP ? false : { key: 'industry', validator: () => !!industryId, child: <FreelancerIndustryForm user={user} markets={markets} roleId={roleId} onChange={updateState} state={state} /> },
    { key: 'experience', validator: () => !!experience, child: <FreelancerExperienceForm role={label} value={experience} onChange={updateState} state={state} /> },
    { key: 'tags', validator: () => !!skills.length > 0, child: <FreelancerTagsForm tags={tags} value={skills} role={label} industry={industry} onChange={updateState} state={state} /> },
    { key: 'list', validator: () => true, child: <FreelancerRoleList user={user} onboarding /> }
  ].filter(Boolean)

  let { key: stepKey, child, validator } = steps[_stepIndex] ?? { child: null, validator: () => true }
  const valid = validator()

  return (
    <Frame
      user={user}
      maxWidth="xs"
      py={0}
      footerContent={
        <ProgressBarFooter
          valid={valid}
          stepIndex={stepIndex}
          firstStep={firstStep}
          numSteps={numSteps}
          onNext={() => {
            if (stepKey === 'list') {
              if (!onboarding) {
                history.push('/roles')
              } else {
                history.push(onNext())
              }
            } else if (stepKey === 'tags') {
              if (onboarding) {
                firebase
                  .analytics()
                  .logEvent('user_freelancer_skills_submit_click')
              }
              updateState({ stepIndex: stepIndex + 1 })
              createRole(omit(state, ['stepIndex']))
            } else {
              let newIndex = stepIndex + 1
              updateState({ stepIndex: newIndex })
            }
          }}
          onPrev={() => {
            updateState({ stepIndex: stepIndex - 1 })
          }}
        />
      }
    >
      <Box pt={4} />
      {child}
    </Frame>
  )
}

const getIndustriesFromMarkets = (markets, roleId) =>
  uniqBy(
    Object.values(markets).filter((v) => v.roles.some((r) => r.id === roleId)),
    (m) => m.industryId,
  )
    .sort((a, b) => a.industry.localeCompare(b.industry))
    .map((m) => ({ label: m.industry, id: m.industryId }))

const FreelancerIndustryForm = ({ markets, onChange, state, roleId }) => {
  const items = getIndustriesFromMarkets(markets, roleId)

  return (
    <>
      <Heading subheading="Un-check anything that's a bad fit.">
        What kinds of companies should see you?
      </Heading>

      <Checklist
        multicheck
        label="Next"
        items={items}
        initialState={items}
        onChange={(items) => {
          const [item] = items
          onChange({
            industry: item?.label,
            industryId: item?.id,
            industryIds: items.map((i) => i.id),
          })
        }}
      />
    </>
  )
}

const FreelancerRoleForm = ({ hasRoles, markets, onChange, state }) => {
  return (
    <>
      <Heading subheading="You can add other roles later">
        Choose your primary role
      </Heading>

      <RoleList
        initialState={[{ label: state.label }]}
        markets={markets}
        onChange={(items) => {
          const [role] = items
          const industries = getIndustriesFromMarkets(markets, role.roleId)
          if (!industries[0]) return

          onChange({
            ...role,
            industry: industries[0].label,
            industryId: industries[0].id,
            industryIds: industries.map((i) => i.id),
          })
        }}
      />
    </>
  )
}

export const FreelancerExperienceForm = ({
  onChange = () => { },
  onSubmit,
  value: defaultValue = 0,
  role = '',
  buttonLabel = 'Next',
}) => {
  const [experience, setExperience] = useState(defaultValue)
  const article = /a|e|i|o|u/.test(role.toLowerCase()[0]) ? 'an' : 'a'

  return (
    <>
      <Heading>
        How many years have you been {article} {role}?
      </Heading>

      <ExperienceSlider
        value={experience}
        setValue={(v) => {
          setExperience(v)
          onChange({ experience: v })
        }}
      />
      <Box my={3} />

      {onSubmit && (
        <Button
          flex={1}
          onClick={() => onSubmit({ experience })}
          style={{ width: '100%' }}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  )
}

export const FreelancerTagsForm = (props) => {
  const { contactEmail } = useContent()
  return (
    <>
      <Heading>Choose up to 15 specialties for your {props.role} role, including level of seniority if appropriate.</Heading>
      <Typography>
        Are we missing a skill? Email us at {contactEmail}
      </Typography>

      <TagsForm {...props} />
    </>
  )
}

const debouncedSearchRoles = (markets) => {
  const roles = uniqBy(
    Object.values(markets)
      .map((m) => m.roles.sort((a, b) => a.name.localeCompare(b.name)))
      .flat(),
    (m) => m.id,
  )
  return debounce((query, callback) => {
    if (query === '')
      return callback(
        roles.map((r) => ({ roleId: r.id, label: r.name.trim() })),
      )
    const fuse = new Fuse(
      roles.map((r) => ({ roleId: r.id, label: r.name.trim() })),
      {
        keys: ['label'],
        minMatchCharLength: 2,
        distance: 100,
        threshold: 0.3,
      },
    )
    const results = fuse.search(query)
    callback(results.map((r) => r.item))
  }, 200)
}

const RoleList = ({ markets, onChange, onSubmit, initialState }) => {
  const [initialItems] = useState(
    uniqBy(getRolesFromMarkets(markets), (r) => r.roleId),
  )
  const [items, setItems] = useState(initialItems)
  const searchRoles = useMemo(() => debouncedSearchRoles(markets), [markets])
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    setItems(
      uniqBy(
        Object.values(markets)
          .map((m) => m.roles)
          .flat(),
        (m) => m.id,
      )
        .map((r) => ({ roleId: r.id, label: r.name.trim() }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
  }, [markets])

  return (
    <>
      <Typography
        style={{ cursor: 'pointer', textAlign: 'center', fontSize: 12 }}
        onClick={() => setDialogOpen(true)}
      >
        Are we missing a role?
      </Typography>
      <AddRoleDialog onClose={() => setDialogOpen(false)} open={dialogOpen} />
      <TextField
        name="query"
        label="Search"
        placeholder="Enter your role or scroll the list"
        onChange={(e) => {
          const query = e.target.value
          if (query === '') {
            setItems(initialItems)
          } else {
            searchRoles(query, setItems)
          }
        }}
      />

      <Checklist
        label="Next"
        onSubmit={onSubmit}
        onChange={onChange}
        initialState={initialState}
        items={items}
      />
    </>
  )
}