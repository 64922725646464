import React from 'react'
import { Admin, Resource } from 'react-admin'
import { Redirect } from 'react-router-dom'
import { firestoreProvider } from 'containers/Admin/firestoreProvider'
import FirebaseAuthProvider from 'containers/Admin/firebaseAuthProvider'
import { RoleList, RoleEdit, RoleCreate } from 'containers/Admin/roles'
import { CityList, CityEdit, CityCreate } from 'containers/Admin/cities'
import { ContentList, ContentEdit, ContentCreate } from 'containers/Admin/contents'
import { UserList, UserEdit, UserCreate } from 'containers/Admin/users'
import { TagList, TagEdit, TagCreate } from 'containers/Admin/tags'
import {
  IndustryList,
  IndustryEdit,
  IndustryCreate,
} from 'containers/Admin/industries'
import { DiversityTagList, DiversityTagEdit, DiversityTagCreate } from 'containers/Admin/diversitytags'
import { GenderTagList, GenderTagEdit, GenderTagCreate } from 'containers/Admin/gendertags'
import { OnboardingConfigList, OnboardingConfigEdit, OnboardingConfigCreate } from './onboarding'
import { firebase } from 'utils'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import { DIVERSITY_TAGS_ENABLED, GENDER_TAGS_ENABLED } from 'constants'

const dataProvider = firestoreProvider(firebase)
const authProvider = FirebaseAuthProvider()
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
  onMissingKey: (key, _, __) => key,
})
const AdminView = ({ history }) => {
  return (
    <Admin
      history={history}
      customRoutes={[<Redirect exact from="/admin" to="/admin/cities" />]}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      title="React Admin"
    >
      <Resource
        name="admin/cities"
        options={{ label: 'Cities' }}
        list={CityList}
        edit={CityEdit}
        create={CityCreate}
      />
      <Resource
        name="admin/roles"
        options={{ label: 'Roles' }}
        list={RoleList}
        edit={RoleEdit}
        create={RoleCreate}
      />
      <Resource
        name="admin/industries"
        options={{ label: 'Industries' }}
        list={IndustryList}
        edit={IndustryEdit}
        create={IndustryCreate}
      />
      <Resource
        name="admin/tags"
        options={{ label: 'Tags' }}
        list={TagList}
        edit={TagEdit}
        create={TagCreate}
      />
      <Resource
        name="admin/users"
        options={{ label: 'Users' }}
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
      />
      <Resource
        name="admin/onboarding"
        options={{ label: "Onboarding Steps"}}
        list={OnboardingConfigList}
        edit={OnboardingConfigEdit}
        create={OnboardingConfigCreate}
      />
      <Resource
        name="admin/contents"
        options={{ label: 'Content' }}
        list={ContentList}
        edit={ContentEdit}
        create={ContentCreate}
      />
      <Resource
        name="admin/diversitytags"
        options={{ label: 'Diversity Tags' }}
        list={DIVERSITY_TAGS_ENABLED ? DiversityTagList : null}
        edit={DiversityTagEdit}
        create={DiversityTagCreate}
      />

      <Resource
        name="admin/gendertags"
        options={{ label: 'Gender Tags' }}
        list={GENDER_TAGS_ENABLED ? GenderTagList : null}
        edit={GenderTagEdit}
        create={GenderTagCreate}
      />
    </Admin>
  )
}

export default AdminView
