import React, { useState } from 'react'
import {
  Button,
  TextField,
  Heading,
  Typography,
  Frame,
} from 'components'
import { updateUser, useContent } from 'utils'
import { Box } from '@material-ui/core'
import { ProgressBarFooter } from './FreelancerProgressBarFooter'
import { VALID_URI_REGEX } from '../FreelancerProfile/FreelancerEditProfile'
import { NUM_ONBOARDING_STEPS } from 'constants'

const FreelancerWebsiteForm = ({ user, onNext, stepIndex, totalSteps }) => {
  const [linkCount, setLinkCount] = useState(1)
  const [websites, setWebsites] = useState(['', '', ''])
  const [websitePasswords, setWebsitePasswords] = useState(['', '', ''])
  const { onboarding } = useContent()

  const onSubmit = () => {
    onNext()
    const _websites = (websites || []).filter(
      (s) => !!VALID_URI_REGEX.test(s),
    )
    updateUser(user, {
      websites: _websites,
      websitePasswords,
    })
  }
  if (!onboarding)
    return null

  return (
    <Frame maxWidth="xs">
      <Heading>
        {onboarding['website-heading'] || 'Showcase your work. Portfolio links go here.'}
      </Heading>

      <Box flex={1} mb={3}>
        {new Array(linkCount).fill('').map((_, i) =>
          <Box key={i} display="flex" style={{ gap: 20 }}>
            <TextField value={websites[i]} onChange={(v) => setWebsites(websites.map((s, j) => j === i ? v.target.value : s))} required name="url" label="Portfolio Site URL" />
            <TextField value={websitePasswords[i]} onChange={(v) => setWebsitePasswords(websitePasswords.map((s, j) => j === i ? v.target.value : s))} required name="password" label="Portfolio Site Password" />
          </Box>
        )}

      </Box>
      <Box flex={1} mb={6}>
        <Typography>
          {onboarding['website-subheading'] || 'NOTE: If your site requires a password, share it. Only CASO-approved studios have access to your profile'}
        </Typography>
      </Box>

      {linkCount < 3 && <Box flex={1} display="flex" flexDirection="column" style={{ gap: 20 }}>
        <Typography>
          Do you have a blog? Is your work on instagram?
          <br />
          Maybe you're a mini company with a website?
          <br />
          Add up to 3 additional links.
        </Typography>
        <Box flex={1} display="flex" justifyContent="center" style={{ gap: 20 }}>
          <Button variant="contained" onClick={() => setLinkCount(linkCount < 3 ? linkCount + 1 : 3)} my={2}>
            Add another link
          </Button>
        </Box>
      </Box>}

      <ProgressBarFooter
        valid={true}
        stepIndex={stepIndex}
        firstStep={stepIndex}
        numSteps={totalSteps}
        onNext={onSubmit}

      />
    </Frame >
  )
}

export default FreelancerWebsiteForm
