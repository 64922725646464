import { Box, Button, Typography } from 'components'

export const ProgressBarFooter = (props) => (
    <Box
      bgcolor="secondary.main"
      position="fixed"
      bottom={{ xs: 50, sm: 0 }}
      left={0}
      right={0}
      zIndex={99}
    >
      <Box flex={1} flexVariant="flex column justify-end">
        <Box flexDirection="column">
          <Box
            height="2px"
            width={props.stepIndex * (100 / props.numSteps) + '%'}
            bgcolor={process.env.REACT_APP_PRIMARY_COLOR}
          />
          <Box flexVariant="flex center" py={2.5} borderTop="1px solid #DDDDDD">
            <Button
              disabled={props.stepIndex === props.firstStep}
              style={{ opacity: props.stepIndex === props.firstStep ? 0.5 : 1 }}
              onClick={props.onPrev}
              variant="outlined"
            >
              Previous
            </Button>
            <Box mx={3.5} />
            <Typography>
              {props.stepIndex} of {props.numSteps}
            </Typography>
            <Box mx={3.5} />
            <Button
              disabled={!props.valid}
              onClick={props.onNext}
              color="primary"
              variant="contained"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )