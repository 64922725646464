import React, { useState } from 'react'
import { Heading, Checklist, Box, Frame, Button } from 'components'
import { updateUser } from 'utils'
import { ProgressBarFooter } from './FreelancerProgressBarFooter'
import { NUM_ONBOARDING_STEPS } from 'constants'
import { useHistory } from 'react-router-dom'
import { FreelancerAvailabilityStartDate } from '../FreelancerAvailability/FreelancerAvailabilityStartDate'

const FreelancerSelectDesiredCommitment = ({
  user,
  onSubmit = () => { },
  onboarding = false,
  onNext,
  stepIndex,
  totalSteps,
}) => {
  const { fullTime, partTime, freelance } = user.desiredCommitments || {}
  const [state, setState] = useState(
    {
      desiredCommitments:
      {
        fullTime: fullTime || false,
        partTime: partTime || false,
        freelance: freelance || false,
      },
      availableFrom: Date.now()
    }
  )
  const history = useHistory()

  const items = [
    { id: 'fullTime', label: 'Contract: Full Time', value: fullTime },
    { id: 'partTime', label: 'Contract: Part Time', value: partTime },
    { id: 'freelance', label: 'Freelance (IC)', value: freelance },
  ]

  const initialItems = items.filter(el => {
    if (el.value === true){
      return el
    }
  })

  const handleChange = (checkedItems) => {
    setState({...state,
      desiredCommitments:
      {
        fullTime: checkedItems.some(el => el.id === 'fullTime'),
        partTime: checkedItems.some(el => el.id === 'partTime'),
        freelance: checkedItems.some(el => el.id === 'freelance'),
      }
    })
    // checkedItems.length > 0 ? setNextValid(true) : setNextValid(false)
  }


  return (
    <Frame maxWidth="xs" user={user}>
      <Box mx="auto" maxWidth={430}>
        <Heading subheading="Click all that apply.">What kind of work are you looking for?</Heading>

        <Checklist
          buttonLabel="Next"
          multicheck
          items={items}
          initialState={initialItems}
          onChange={handleChange}
          onSubmmit={onNext}
        />
        <FreelancerAvailabilityStartDate user={user} onboarding={onboarding} onUpdate={date => setState({ ...state, availableFrom: date })} />

      </Box>
      {onboarding ? (
        <ProgressBarFooter
          valid={true}
          stepIndex={stepIndex}
          firstStep={stepIndex}
          numSteps={totalSteps}
          onNext={() => updateUser(user, state).then(onSubmit)}
          onPrev={() => { }}
        />
      ) : (
        <Button
          // disabled={!nextValid}
          type='submit'
          onClick={() => updateUser(user, state).then(history.push('/freelancer'))}
        >Save</Button>
      )}
    </Frame>
  )
}

export default FreelancerSelectDesiredCommitment
