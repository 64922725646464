import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import FreelancerCreateForm from './FreelancerCreateForm'
import FreelancerWebsiteForm from './FreelancerWebsiteForm'
import FreelancerSelectCity from './FreelancerSelectCity'
import FreelancerSelectOntarioWorkingStatus from './FreelancerSelectOntarioWorkingStatus'
import FreelancerSelectRelocationStatus from './FreelancerSelectRelocationStatus'
import FreelancerSelectDesiredCommitment from './FreelancerSelectDesiredCommitment'
import FreelancerCredits from '../FreelancerCredits'
import FreelancerUploadCv from './FreelancerUploadCv'
import FreelancerDiversityTags from '../FreelancerDiversityTags'
import FreelancerGenderTags from '../FreelancerGenderTags'
import FreelancerAvatar from '../FreelancerCreate/FreelancerAvatar'
import FreelancerSelectEmploymentStatus from './FreelancerSelectEmploymentStatus'
import { FreelancerAddRole } from '../FreelancerRoles/FreelancerAddRole'
import FreelancerEquipment from '../FreelancerEquipment'
import FreelancerSelfIdentification from '../FreelancerSelfIdentification'
import { useOnboardConfig, useTags } from 'utils'
import {
  ONTARIO_WORKING_STATUS,
  ONTARIO_RESIDENCE_STATUS,
  COMMITMENT_STATUS,
  CREDITS_ENABLED,
  DIVERSITY_TAGS_ENABLED,
  CV_ONBOARD_ENABLED,
  GENDER_TAGS_ENABLED,
  PROFILE_PICTURES_ENABLED,
  USE_DEMO_LANDER,
  NUM_ROLE_STEPS,
} from 'constants'

const FreelancerCreateView = ({ cities, user, markets, createRole }) => {
  // const [hasSkippedWebsite, setHasSkippedWebsite] = useState(false)
  // const [hasSkippedCredits, setHasSkippedCredits] = useState(false)
  // const [hasSkippedCv, setHasSkippedCv] = useState(false)
  // const [hasSkippedDiversityTags, setHasSkippedDiversityTags] = useState(false)
  // const [hasSkippedGenderTags, setHasSkippedGenderTags] = useState(false)
  // const [hasSkippedPicture, setHasSkippedPicture] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  let renderedStepIndex = stepIndex + 1

  const onboardSteps = ['cv', 'profilePicture', 'city', 'credits', 'website',]


  const onboardConfig = useOnboardConfig()
  const tags = useTags()

  if (!user) {
    return <FreelancerCreateForm />
  }

  if (!onboardConfig) {
    return null
  }



  const totalSteps = onboardSteps.length + NUM_ROLE_STEPS
  switch (onboardConfig[stepIndex]?.name) {
    case 'website':
      return <FreelancerWebsiteForm user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'city':
      return <FreelancerSelectCity onboarding cities={cities} user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'ontarioWorkingStatus':
      return <FreelancerSelectOntarioWorkingStatus onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'relocationStatus':
      return <FreelancerSelectRelocationStatus onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'desiredCommitment':
      return <FreelancerSelectDesiredCommitment onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'genderTags':
      return <FreelancerGenderTags onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'diversityTags':
      return <FreelancerDiversityTags onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'credits':
      return <FreelancerCredits onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'cv':
      return <FreelancerUploadCv onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'employmentStatus':
      return <FreelancerSelectEmploymentStatus onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'profilePicture':
      return <FreelancerAvatar onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'roles':
      return <FreelancerAddRole
        onboarding
        user={user}
        stepIndex={stepIndex + 1}
        totalSteps={totalSteps}
        markets={markets}
        createRole={createRole}
        tags={tags}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'equipment':
      return <FreelancerEquipment onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps}
        onNext={() => {
          setStepIndex(stepIndex + 1)
        }}
        onSubmit={() => {
          setStepIndex(stepIndex + 1)
        }} />
    case 'selfIdentification':
      return <FreelancerSelfIdentification onboarding user={user} stepIndex={stepIndex + 1} totalSteps={totalSteps} />
    default:
      return <Redirect to="/profile?source=onboarding" />
  }
}

export default FreelancerCreateView
