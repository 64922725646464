import React, { useState } from 'react'
import { Heading, Checklist, Box, Frame, Switch } from 'components'
import { updateUser } from 'utils'
import { ProgressBarFooter } from './FreelancerProgressBarFooter'
import { NUM_ONBOARDING_STEPS, RELOCATION_STATUSES } from 'constants'
import { useHistory } from 'react-router-dom'

const FreelancerSelectRelocationStatus = ({
  user,
  stepIndex, 
  totalSteps,
  onSubmit = () => { },
  onboarding,
}) => {
  const [state, setState] = useState({ relocationStatus: null, resident: true })
  const history = useHistory()
  return (
    <Frame maxWidth="xs" user={user}>
      <Box mx="auto" maxWidth={430}>
        <Heading subheading="Select all that apply">Do you currently live in Ontario and are you willing to relocate within Ontario?</Heading>

        <Switch
        color='primary'
        label="I currently reside in Ontario"
        defaultChecked
        value={state.resident}
        onChange={(e) => setState({ ...state, resident: e.target.checked })}
        />
        <Checklist
          buttonLabel="Next"
          items={[
            { id: 1, label: state.resident ? "I am willing to relocate within Ontario" : "I am willing to relocate to Ontario" },
            { id: 0, label: RELOCATION_STATUSES[0] },
          ]}
          onChange={([{ id }]) => setState({ ...state, relocationStatus: id })}
        />

      </Box>
      {onboarding ? (
        <ProgressBarFooter
          valid={typeof state.relocationStatus === 'number'}
          stepIndex={stepIndex}
          firstStep={stepIndex}
          numSteps={totalSteps}
          onNext={() => updateUser(user, state).then(onSubmit)}
          onPrev={() => { }}
        />
      ) : (
        <ProgressBarFooter
          valid={typeof state.relocationStatus === 'number'}
          stepIndex={2}
          firstStep={1}
          numSteps={2}
          onNext={() => updateUser(user, state).then(history.push('/freelancer'))}
          onPrev={() => { }}
        />
        )}

    </Frame>
  )
}

export default FreelancerSelectRelocationStatus
