import { useState, useEffect } from 'react'
import { Box, Typography, Pill, Frame, Button } from 'components'
import { Chip } from '@material-ui/core'
import { useGenderTags, updateUser } from "utils";
import { ProgressBarFooter } from '../FreelancerCreate/FreelancerProgressBarFooter'
import { NUM_ONBOARDING_STEPS, DIVERSITY_TAGS_ENABLED } from 'constants';

export default function FreelancerGenderTags({ user, onboarding, history, onNext, stepIndex, totalSteps }) {
    const [state, setState] = useState({
        genderTags: user.genderTags ? user.genderTags : [],
    })
    const genderTags = useGenderTags()

    // useEffect(() => {
    //     updateUser(user, { genderTags: state.genderTags })
    // }, [state.genderTags])

    if (!genderTags)
        return null

    const userTags = user.genderTags ? user.genderTags : []

    const handleTagClick = (e) => {
        const newTags = state.genderTags
        if (newTags.includes(e.target.textContent)) {
            newTags.splice(newTags.indexOf(e.target.textContent), 1)
            setState({ ...state, genderTags: newTags })
        } else {
            newTags.push(e.target.textContent)
            setState({ ...state, genderTags: newTags })
        }
    }
    const handleSubmit = (e) => {
        // e.preventDefault()
        updateUser(user, { genderTags: state.genderTags })
        if (onboarding) {
            onNext()
            return
        }
        if (DIVERSITY_TAGS_ENABLED) {
            history.push("/diversitytags")
            return
        }
        history.push("/profile")
    }
    return (
        <Frame maxWidth="sm" user={user}>
            <Box mx="auto" maxWidth={530}>
                <Box flex={1} flexVariant="column center" mb={2.5}>
                    <Typography variant="h4" style={{ fontWeight: '600' }}>
                        Gender
                    </Typography>
                    <Box my={2} />
                    <Typography variant="body1">
                        This directory was created specifically for the
                        2SLGBTQIA+ production community. So it's very
                        inclusive and safe. Many Employers have Diversity and
                        Inclusion programs. Also sometimes a project calls for
                        a certain point of view. This step is entirely optional. If
                        you don't want to identify, tap the skip button.
                    </Typography>
                    <Box my={2} />
                </Box>
                <Box my={2} />
                <Box flexVariant="row align-center" flexWrap="wrap">
                    {genderTags.map((genderTag, i) => (
                        <Chip variant={
                            state.genderTags.includes(genderTag.name) ? 'default' : 'outlined'
                        }
                            color="primary"
                            style={{ marginRight: 8, marginBottom: 5 }}
                            key={i}
                            label={genderTag.name}
                            name={genderTag.name}
                            onClick={handleTagClick}
                        />
                    ))}
                </Box>
                {onboarding && (
                    <Box my={2} flexVariant="row justify-space-evenly">
                        <Button variant="outlined" style={{ width: '40%' }} onClick={() => onNext()}>Skip</Button>
                        <Button variant="contained" style={{ width: '40%' }} onClick={() => handleSubmit()}>Save</Button>
                    </Box>
                )}
                {!onboarding && (
                    <Box my={2} flexVariant="row justify-space-evenly">
                        <Button variant="contained" style={{ width: '40%' }} onClick={() => handleSubmit()}>Save</Button>
                        <Button variant="outlined" style={{ width: '40%' }} onClick={() => {
                            if (DIVERSITY_TAGS_ENABLED) {
                                history.push('/diversitytags')
                                return
                            }
                            history.push('/profile')
                        }}>
                            {DIVERSITY_TAGS_ENABLED ? 'Skip' : 'Cancel'}
                        </Button>
                    </Box>
                )}
            </Box>
            {onboarding && (
                <ProgressBarFooter
                    valid={true}
                    stepIndex={stepIndex}
                    firstStep={stepIndex}
                    numSteps={totalSteps}
                    onNext={() => onNext()}
                    onPrev={() => { }}
                />
            )}
        </Frame>
    )
}