import { useState, useEffect } from 'react'
import { Box, Typography, Pill, Frame, Button, Divider } from 'components'
import { Chip } from '@material-ui/core'
import { useDiversityTags, updateUser } from "utils";
import { ProgressBarFooter } from '../FreelancerCreate/FreelancerProgressBarFooter'
import { NUM_ONBOARDING_STEPS } from 'constants';

export default function FreelancerSelfIdentification({ user, onboarding, history, onNext, stepIndex, totalSteps }) {
    const [state, setState] = useState({
        diversityTags: user.diversityTags ? user.diversityTags : [],
    })
    const diversityTags = useDiversityTags()

    if (!diversityTags)
        return null

    const userTags = user.diversityTags ? user.diversityTags : []

    const ethnicityTags = diversityTags.filter((tag) => tag.type === "ethnic")
    const impairmentTags = diversityTags.filter((tag) => tag.type === "impair")

    console.log('ethnic tags: ', ethnicityTags, 'impair tags: ', impairmentTags)

    const handleTagClick = (e) => {
        const newTags = state.diversityTags
        if (newTags.includes(e.target.textContent)) {
            newTags.splice(newTags.indexOf(e.target.textContent), 1)
            setState({ ...state, diversityTags: newTags })
        } else {
            newTags.push(e.target.textContent)
            setState({ ...state, diversityTags: newTags })
        }
    }
    const handleSubmit = (e) => {
        // e.preventDefault()
        updateUser(user, { diversityTags: state.diversityTags })
        if (onboarding) {
            onNext()
        } else {
            history.push("/profile")
        }
    }
    return (
        <Frame maxWidth="sm" user={user}>
            <Box mx="auto" maxWidth={530}>
                <Box flex={1} flexVariant="column center" mb={2.5}>
                    <Typography variant="h4" style={{ fontWeight: '600' }}>
                        Self Indentification
                    </Typography>
                    <Typography variant='subtitle1'>(Optional)</Typography>
                </Box>
                <Divider />
                <Typography variant="subtitle1" style={{ marginTop: 20, marginBottom: 20 }}>
                    Select one from this group
                </Typography>
                <Box flexVariant="row align-center" flexWrap="wrap" maxWidth='40rem'>
                    {ethnicityTags.map((diversityTag, i) => (
                        <Chip variant={
                            state.diversityTags.includes(diversityTag.name) ? 'default' : 'outlined'
                        }
                            color="primary"
                            style={{ marginRight: 16, marginBottom: 5 }}
                            key={i}
                            label={diversityTag.name}
                            name={diversityTag.name}
                            onClick={handleTagClick}
                        />
                    ))}
                </Box>
                <Divider />
                <Typography variant='subtitle1' style={{ marginTop: 20, marginBottom: 20 }}>Select any that apply from this group</Typography>
                <Box flexVariant="row align-center" flexWrap="wrap" maxWidth='40rem'>
                    {impairmentTags.map((diversityTag, i) => (
                        <Chip variant={
                            state.diversityTags.includes(diversityTag.name) ? 'default' : 'outlined'
                        }
                            color="primary"
                            style={{ marginRight: 16, marginBottom: 5 }}
                            key={i}
                            label={diversityTag.name}
                            name={diversityTag.name}
                            onClick={handleTagClick}
                        />
                    ))}
                </Box>
                {!onboarding && (
                    <Box my={2} flexVariant="row justify-space-evenly">
                        <Button variant="contained" style={{ width: '40%' }} onClick={() => handleSubmit()}>Save</Button>
                        <Button variant="outlined" style={{ width: '40%' }} onClick={() => history.push('/profile')}>Cancel</Button>
                    </Box>
                )}
            </Box>
            {onboarding && (
                <ProgressBarFooter
                    valid={true}
                    stepIndex={stepIndex}
                    firstStep={stepIndex}
                    numSteps={totalSteps}
                    onNext={() => handleSubmit()}
                    onPrev={() => { }}
                />
            )}
        </Frame>
    )
}