import React, { useState, useEffect } from 'react'
import { Heading, Box, Frame, Button, IconButton, DeleteIcon, LinkIcon, TextField } from 'components'
import { List as MUIList, ListItem as MUIListItem, ListItemText, Link, } from '@material-ui/core'
import { updateUser } from 'utils'
import { ProgressBarFooter } from '../FreelancerCreate/FreelancerProgressBarFooter'
import { NUM_ONBOARDING_STEPS } from 'constants'
import { useHistory } from 'react-router-dom'

const CreditList = ({ items, onDelete }) => {
    const handleClick = (index) => {
        onDelete(index)
    }
    return (
        <MUIList>
            {items.map(({ projectName, role, projectUrl, year }, index) => {
                return (
                    <MUIListItem key={index}>
                        <ListItemText
                            primary={projectName}
                            secondary={role}
                        />
                        <ListItemText secondary={year} />
                        {projectUrl ? <Link href={projectUrl} target="_blank" rel="noopener noreferrer"><IconButton><LinkIcon /></IconButton></Link> : null}
                        <IconButton onClick={() => handleClick(index)}><DeleteIcon /></IconButton>
                    </MUIListItem>
                )
            })}
        </MUIList>
    )
}

const AddCreditForm = ({ onCreditSubmit, formHidden = false }) => {
    const [formState, setFormState] = useState({ projectName: '', role: '', projectUrl: '', year: '' })
    const [urlError, setUrlError] = useState(false)
    const [urlHelperText, setUrlHelperText] = useState('')

    const VALID_URI_REGEX = /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/

    const handleCreditSubmit = e => {
        e.preventDefault()
        if (urlError) {
            return
        }
        onCreditSubmit(formState)
        setFormState({ projectName: '', role: '', projectUrl: '', year: '' })
    }
    const handleChange = e => {
        const { name, value } = e.target
        setFormState({ ...formState, [name]: value })
    }

    useEffect(() => {
        if (formState.projectUrl === '' || formState.projectUrl.match(VALID_URI_REGEX)) {
            setUrlError(false)
            setUrlHelperText('')
        } else {
            setUrlError(true)
            setUrlHelperText('Please enter a valid URL')
        }
    }, [formState.projectUrl])
    return (
        <form hidden={formHidden} autoComplete="off" onSubmit={handleCreditSubmit} onChange={handleChange}>
            <TextField value={formState.projectName} required label="Project Name" placeholder="Awesome Movie" name="projectName" />
            <TextField value={formState.role} label="Role" placeholder="Director" required name="role" />
            <TextField value={formState.projectUrl} error={urlError} helperText={urlHelperText} label="Link to Project (optional)" placeholder="https://imdb.com/awesomemovie" name="projectUrl" />
            <TextField value={formState.year} label="Duration/Year" placeholder="Ex. 2020 or 2020-2021" required name="year" />
            <Button type="submit">Add</Button>
        </form>
    )
}
const FreelancerCredits = ({
    user,
    onboarding,
    onNext,
    stepIndex,
    totalSteps,
}) => {
    const [state, setState] = useState(user.credits ? { credits: user.credits } : { credits: [] })
    const [addingRole, setAddingRole] = useState(true)

    const history = useHistory()

    const updateUserCredits = (credit) => {
        setAddingRole(false)
        setState({ ...state, credits: [...state.credits, credit] })
    }

    const handleDeleteCredit = (index) => {
        const creditList = state.credits.toSpliced(index, 1)
        setState({ ...state, credits: creditList })
    }

    const handleClick = () => {
        setAddingRole(true)
    }

    const onSubmit = () => {
        if (onboarding) {
            onNext()
            return
        }
        history.push('/freelancer')
    }

    useEffect(() => {
        if (!user.credits || user.credits.length !== state.credits.length) {
            updateUser(user, state).then(() => {
            })
        }
    }, [state])
    return (
        <Frame maxWidth="xs" user={user}>
            <Box mx="auto">
                <Heading>Add Credits to your Profile.</Heading>

                <CreditList items={state.credits} onDelete={handleDeleteCredit} />
                <AddCreditForm formHidden={!addingRole} user={user} onCreditSubmit={updateUserCredits} />
                <Box hidden={addingRole}>
                    <Button onClick={handleClick} color='primary'>Add a Credit</Button>
                </Box>

            </Box>
            {onboarding ? (
                <ProgressBarFooter
                    valid={true}
                    stepIndex={stepIndex}
                    firstStep={stepIndex}
                    numSteps={totalSteps}
                    onNext={() => updateUser(user, state).then(onSubmit)}
                    onPrev={() => { }}
                />
            ) : (
                <Button onClick={onSubmit} style={{ width: '50%', marginLeft: '50%' }} variant='contained' color='primary'>Done</Button>
            )}
        </Frame>
    )
}

export default FreelancerCredits